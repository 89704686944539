import { render } from "react-dom";

import { RootVNHEUI } from "./root-vnhe-ui";

async function enableMocking() {
  if (process.env.NODE_ENV !== "development" || window.localStorage.getItem("hydra") === "true") {
    return;
  }

  const { worker } = await import("./msw/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() => {
  render(<RootVNHEUI />, document.getElementById("root"));
});
